export namespace EquiReglementation {
  export type Platform = 'web' | 'ios' | 'android';

  export namespace Database {
    export interface Timestamp {
      toDate: () => Date;
    }

    export interface Question {
      id: string;
      order: number;
      filled: boolean;
      description: string;
      informative?: boolean;
      conditions?: QuestionCondition[];
      answers: QuestionAnswers;
    }

    export interface Category {
      id: string;
      questions: Question[];
      order: number;
      name: string;
      image?: string;
      color?: string;
      certification?: CategoryCertification;
    }
    export interface Survey {
      id: string;
      name: string;
      categories: Category[];
      type: SURVEY_TYPES;
      preventCertificationProcess: boolean;
      payment?: {
        enabled: boolean;
      };
    }

    export interface SubscriptionPlatformData {
      productId: string;
    }
    export interface Subscription {
      id: string;
      metadata: {
        surveyId: string;
      };
      startDate: EquiReglementation.Database.Timestamp;
      endDate: EquiReglementation.Database.Timestamp;

      web?: SubscriptionPlatformData;
      android?: SubscriptionPlatformData;
      ios?: SubscriptionPlatformData;
      macos?: SubscriptionPlatformData;
      windows?: SubscriptionPlatformData;
    }
  }
}

export namespace EquiReglementation {
  export enum NOTIFICATION_TARGET_TYPES {
    NONE = 'none',
    SURVEY = 'survey',
    COACHING = 'coaching',
    LINK = 'link',
    PASSES = 'passes',
  }

  export enum SURVEY_TYPES {
    CERTIFICATION = 'certification',
    REVIEW = 'review',
  }

  export enum SURVEY_CONDITION_TYPES {
    PRE_ANSWERED = 'preAnswered',
    ANSWERED_LEGACY = 'answered',
    SHOW = 'show',
  }

  export interface Targeting {
    departments?: string[];
    jobs?: string[];
    activityDomains?: string[];
    certified?: string[];
  }

  // NOTIFICATION

  export interface NotificationData {
    target: NOTIFICATION_TARGET_TYPES;
    target_survey?: string;
    target_link?: string;
  }

  export interface NotificationTargeting extends Targeting {
    surveyAnswerRate: { target: string; value: number }[];
    surveySuccessRate: { target: string; value: number }[];
  }
  export interface Notification {
    title?: string;
    body: string;
    targeting?: NotificationTargeting;
    data?: {
      [key: string]: string;
    };
  }

  export interface BackendNotification extends Notification {
    stats?: { pushSent: number; pushSuccess: number; pushFailure: number; sent: number };
  }

  // SURVEY

  export interface QuestionAnswer {
    value: number;
    label: string;
    score?: number;
  }

  export interface QuestionCondition {
    question: string;
    givenAnswer: QuestionAnswer;
    type: SURVEY_CONDITION_TYPES;
    met?: boolean;
    targetGivenAnswer?: QuestionAnswer;
  }

  export interface QuestionAnswers {
    values: QuestionAnswer[];
    minValueToReach?: number;
  }

  export interface Question extends EquiReglementation.Database.Question {
    givenAnswer: QuestionAnswer;
    certification: CategoryCertification;
  }

  export interface CategoryCertification {
    minScore: number;
    certified: boolean;
    maxScore: number;
    score: number;
  }

  export interface Category extends EquiReglementation.Database.Category {
    certification: CategoryCertification;
    validRate?: number;
    answered: number;
    resultColor: string;
    fillRatio: number;
    successRatio: number;
    questions: EquiReglementation.Question[];
  }

  export interface SurveyCertification {
    certified: boolean;
    score: number;
  }

  export interface Survey extends EquiReglementation.Database.Survey {
    categories: Category[];
    certification: SurveyCertification;
    fillRatio: number;
    successRatio: number;
  }

  type SurveyMetadata = {
    lastEdit?: EquiReglementation.Database.Timestamp;
    lastVisit?: EquiReglementation.Database.Timestamp;
  };

  export type UserSurveyMetadata = SurveyMetadata & {
    type: EquiReglementation.SURVEY_TYPES;
    categories?: Record<string, SurveyMetadata>;
    fillRatio: number;
    successRatio: number;
    certification: SurveyCertification;
  };
  export interface UserMetadata {
    surveys?: Record<string, UserSurveyMetadata>;
  }

  export interface UserFCM {
    platform: string;
    updated: EquiReglementation.Database.Timestamp;
    created: EquiReglementation.Database.Timestamp;
  }

  export interface UserIntegrator {
    identifiers: string[];
  }

  export interface User {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    jobs: string[];
    activityDomains: string[];
    department: string;
    created: EquiReglementation.Database.Timestamp;
    metadata?: UserMetadata;
    integrators?: Record<string, UserIntegrator>;

    fcm?: Record<string, UserFCM>;
  }

  export interface Integrator {
    id: string;
    identifier: string;
    apiSecret: string;
    hooks?: {
      surveyUserQuestionInteraction?: string;
      surveyScoringUpdated?: string;
    };
  }

  export interface CoachingCategory {
    id: string;
    name: string;
  }
}
