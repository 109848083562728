import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/remote-config';

import { SecurityUtils } from '@valotvince/equireglementation-utils';

export const fetchAndActivateConfig = () => firebase.remoteConfig().fetchAndActivate();

export const getRemoteConfigKey = remoteKey => firebase.remoteConfig().getString(remoteKey);

export const initializeFirebase = config => {
  firebase.initializeApp(config);

  // To test functions in local mode
  // firebase .app().functions('europe-west1').useFunctionsEmulator('http://localhost:5001');
};

export const snapshotToCollection = (snapshots, withSnapshot = false) => {
  const items = [];

  snapshots.forEach(snapshot =>
    items.push({
      snapshot: withSnapshot ? snapshot : undefined,
      ...snapshot.data(),
      id: snapshot.id,
    }),
  );

  return items;
};

export const getFileURL = ref => firebase.storage().ref().child(ref).getDownloadURL();

export const storeFile = (collection, extension, file) => {
  const childRef = firebase.storage().ref().child(`${collection}/${SecurityUtils.genGuid()}.${extension}`);

  return childRef.put(file, { cacheControl: 'public,max-age=2678400' });
};

export const removeDocument = (collection, id) => firebase.firestore().collection(collection).doc(id).delete();

export const saveDocument = (collection, { id, ...documentFields }) => {
  const collectionRef = firebase.firestore().collection(collection);

  if (id) {
    return collectionRef
      .doc(id)
      .set({ ...documentFields, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
  }

  return collectionRef.add({ ...documentFields, created: firebase.firestore.FieldValue.serverTimestamp() });
};

export const updateDocument = (collection, id, documentFields) => {
  const data = { ...documentFields, updated: firebase.firestore.FieldValue.serverTimestamp() };

  return firebase.firestore().collection(collection).doc(id).update(data);
};

export const getDocument = (collection, id) =>
  firebase
    .firestore()
    .collection(collection)
    .doc(id)
    .get()
    .then(document => (document.exists ? { ...document.data(), id: document.id } : null));

export const getCollection = (collection, filters = {}) => {
  let collectionRef = firebase.firestore().collection(collection);

  Object.keys(filters).forEach(filterName => {
    filters[filterName].forEach(filter => {
      collectionRef = collectionRef[filterName](...filter);
    });
  });

  return collectionRef.get().then(snapshotToCollection);
};

export const getRealtimeDocument = (documentPath, callback) =>
  firebase
    .firestore()
    .doc(documentPath)
    .onSnapshot(snapshot => callback({ ...snapshot.data(), id: snapshot.id }));

export const getRealtimeCollection = (collection, filters = {}, callback) => {
  let collectionRef = firebase.firestore().collection(collection);

  Object.keys(filters).forEach(filterName => {
    filters[filterName].forEach(filter => {
      collectionRef = collectionRef[filterName](...filter);
    });
  });

  return collectionRef.onSnapshot(snapshot => {
    callback(snapshotToCollection(snapshot, true));
  });
};

export { firebase };
