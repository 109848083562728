import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#63A46C',
      dark: 'rgb(48, 83, 53)',
    },
    secondary: {
      main: '#FFF',
    },
    error: {
      main: red.A400,
    },
  },
});
