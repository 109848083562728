export const genGuid = () => {
  let time = new Date().getTime();

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
    // eslint-disable-next-line no-bitwise
    const replacement = (time + Math.random() * 16) % 16 | 0;

    time = Math.floor(time / 16);

    if (char === 'x') {
      return replacement.toString(16);
    }

    // eslint-disable-next-line no-bitwise
    return ((replacement & 0x3) | 0x8).toString(16);
  });
};
