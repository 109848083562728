import React, { useEffect, useState, Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { EquiReglementation } from '@valotvince/equireglementation-utils';
import { version } from '../package.json';

import { theme } from './modules/ui/theme';

import { firebase, getDocument, initializeFirebase } from './modules/data/firebase';
import { SignIn } from './modules/navigation/components/signIn.component';
import { store } from './store';
import { authSignIn, authSignOut } from './modules/auth/auth.actions';

Sentry.init({ dsn: 'https://f267ab35d5fc4eab930a67d09175a9b9@sentry.io/1860123', release: version });

initializeFirebase(
  __DEV__ || __STAGING__
    ? {
        apiKey: 'AIzaSyBHVD20lukPS5asvhvJ4U4rLddYPERUhbY',
        authDomain: 'equi-reglementation-staging.firebaseapp.com',
        databaseURL: 'https://equi-reglementation-staging.firebaseio.com',
        projectId: 'equi-reglementation-staging',
        storageBucket: 'equi-reglementation-staging.appspot.com',
        messagingSenderId: '488368536610',
        appId: '1:488368536610:web:a090d962a783684cba9e7b',
        measurementId: 'G-X07L0QFSG8',
      }
    : {
        apiKey: 'AIzaSyCa6XWniP9IuAiEPGTZyitvmTdA8YCWehU',
        authDomain: 'outil-auto-diagnostique-cheval.firebaseapp.com',
        databaseURL: 'https://outil-auto-diagnostique-cheval.firebaseio.com',
        projectId: 'outil-auto-diagnostique-cheval',
        storageBucket: 'outil-auto-diagnostique-cheval.appspot.com',
        messagingSenderId: '142578479802',
        appId: '1:142578479802:web:21b0b8fa351820f4',
        measurementId: 'G-MK2VTNEV9E',
      },
);

// eslint-disable-next-line no-inline-comments
const App = lazy(() => import(/* webpackChunkName: "app" */ './app'));

const Main = () => {
  const [firstLoading, setFirstLoading] = useState(false);
  const [user, setUser] = useState<EquiReglementation.User | null>();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async response => {
        if (response) {
          const { claims } = await response.getIdTokenResult();

          if (claims.admin) {
            const document = (await getDocument('users', response.uid)) as EquiReglementation.User;

            store.dispatch(authSignIn(document));
            setUser(document);
            setFirstLoading(true);
          } else {
            firebase.auth().signOut();
            store.dispatch(authSignOut());
            setUser(null);
          }

          return;
        }

        setFirstLoading(true);
      }),
    [],
  );

  let content;

  if (user) {
    content = <App />;
  } else if (firstLoading) {
    content = <SignIn />;
  }

  return (
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={null}>{content}</Suspense>
        </ThemeProvider>
      </Provider>
    </Router>
  );
};

const root = createRoot(document.querySelector('#root'));

root.render(<Main />);
