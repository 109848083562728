import { EquiReglementation } from './types';
import { colors } from './colors';

export const getColorFromValue = (value: number) => {
  switch (String(value)) {
    case '1':
      return colors.green;
    case '0.75':
      return colors.orangeGreen;
    case '0.5':
      return colors.orange;
    case '0.25':
      return colors.orangeRed;
    case '0':
      return colors.red;
    case '-1':
      return colors.gray;

    default:
      return '#FFFFFF';
  }
};

const getColorFromBounds = (value: number) => {
  if (value < 0) {
    return colors.gray;
  }

  if (value <= 0.125) {
    return colors.red;
  }

  if (value <= 0.375) {
    return colors.orangeRed;
  }

  if (value <= 0.625) {
    return colors.orange;
  }

  if (value <= 0.875) {
    return colors.orangeGreen;
  }

  return colors.green;
};

/**
 * Gives back the questions that should count in results computations
 * Removes questions with answer having -1 value (informative or not to be taken into account)
 */
const getAccountableQuestions = (questions: EquiReglementation.Question[]) =>
  questions.filter(
    ({ informative, givenAnswer }: EquiReglementation.Question) => !informative && givenAnswer.value >= 0,
  );

/**
 * Returns a validRate from 0 to 100.
 * Be sure to call this method with "accountable" questions as to have the correct valid rate value
 */
const getValidRate = (questions: EquiReglementation.Question[]): number | undefined => {
  if (questions.length) {
    const rate =
      (questions.reduce((accumulator, { givenAnswer }) => {
        return accumulator + givenAnswer.value;
      }, 0) /
        questions.length) *
      100;

    return Math.round(rate * 100) / 100;
  }

  return undefined;
};

export const getPreAnsweredCondition = (conditions: EquiReglementation.QuestionCondition[] = []) => {
  const conditionsOfType = conditions.filter(
    ({ type }) => type === EquiReglementation.SURVEY_CONDITION_TYPES.PRE_ANSWERED,
  );

  return conditionsOfType.find(({ met }) => met);
};

export const areConditionsMet = (
  conditionType: EquiReglementation.SURVEY_CONDITION_TYPES,
  conditions: EquiReglementation.QuestionCondition[] = [],
  strict = false,
) => {
  const conditionsOfType = conditions.filter(({ type }) => type === conditionType);

  if (!conditionsOfType.length) {
    return !strict;
  }

  return !conditionsOfType.find(({ met }) => !met);
};

const meetQuestionConditions = (
  question: EquiReglementation.Database.Question,
  answers: Record<string, EquiReglementation.QuestionAnswer>,
) => {
  const conditions = question.conditions || [];

  return {
    ...question,
    conditions: conditions.map(condition => {
      const answer = answers[condition.question];

      // No response on that one, no need to go further
      if (!answer) {
        return { ...condition, met: false };
      }

      return {
        ...condition,
        met: answer && answer.value === condition.givenAnswer.value,
      };
    }),
  };
};

const meetQuestionsConditions = (
  questions: EquiReglementation.Database.Question[],
  answers: Record<string, EquiReglementation.QuestionAnswer>,
) => questions.map(question => meetQuestionConditions(question, answers));

const prepareSurveyQuestionsData = (
  survey: EquiReglementation.Database.Survey,
  questions: EquiReglementation.Database.Question[],
  answers: Record<string, EquiReglementation.QuestionAnswer>,
) =>
  meetQuestionsConditions(questions, answers).map(({ id, informative, conditions, ...question }) => {
    const { values, minValueToReach } = question.answers;

    const givenUserAnswer = answers[id];
    const givenAnswer = givenUserAnswer && values.find(answer => answer.value === givenUserAnswer.value);

    let resultColor;
    let score = 0;
    let minScore = 0;
    let maxScore = 0;
    let value = 0;
    let filled = !!givenAnswer;

    const minAnswerToReach = values.find(answer => answer.value === minValueToReach);
    const highestAnswer = [...values].sort(
      (a: EquiReglementation.QuestionAnswer, b: EquiReglementation.QuestionAnswer) => (b.score || 0) - (a.score || 0),
    )[0];

    const isCertificationSurvey = survey.type === EquiReglementation.SURVEY_TYPES.CERTIFICATION;

    if (isCertificationSurvey) {
      minScore = (minAnswerToReach && minAnswerToReach.score) || 0;
      maxScore = (highestAnswer && highestAnswer.score) || 0;
    } else {
      // For review surveys, we need to have to highest value to be certified
      minScore = 1;
      // For review surveys, we always max out to 1
      maxScore = 1;
    }

    if (!informative && givenAnswer) {
      resultColor = getColorFromValue(givenAnswer.value);
      value = givenAnswer.value;

      if (isCertificationSurvey) {
        score = givenAnswer.score || 0;
      } else {
        score = value;
      }
    }

    // Do not pre-answer question if the user answered by himself
    if (!givenAnswer) {
      const preAnsweredCondition = getPreAnsweredCondition(conditions);

      if (preAnsweredCondition && preAnsweredCondition.targetGivenAnswer) {
        const { value: targetValue = 0, score: targetScore = 0 } = preAnsweredCondition.targetGivenAnswer;

        resultColor = getColorFromValue(targetValue);
        value = targetValue;

        if (isCertificationSurvey) {
          score = targetScore;
        } else {
          score = targetValue;
        }

        filled = true;
      } else if (areConditionsMet(EquiReglementation.SURVEY_CONDITION_TYPES.ANSWERED_LEGACY, conditions, true)) {
        resultColor = colors.green;
        score = maxScore;
        value = 1;

        filled = true;
      }
    }

    if (
      // either it was an informative question
      (informative && givenAnswer) ||
      // either it wasn't displayed because the condition wasn't met
      !areConditionsMet(EquiReglementation.SURVEY_CONDITION_TYPES.SHOW, conditions) ||
      // either we responsed to the question
      (givenAnswer && givenAnswer.value === -1)
    ) {
      resultColor = getColorFromValue(-1);
      value = -1;
    }

    return {
      ...question,
      id,
      conditions,
      informative,
      filled,
      certification: {
        minScore,
        maxScore,
        certified: score >= minScore || value === -1,
        score,
      },
      givenAnswer: {
        label: (givenAnswer && givenAnswer.label) || '',
        value,
        score,
      },

      resultColor,
    };
  });

export const prepareSurveyCategoryData = (
  survey: EquiReglementation.Database.Survey,
  category: EquiReglementation.Database.Category,
  answers: Record<string, EquiReglementation.QuestionAnswer>,
): EquiReglementation.Category => {
  const questions = prepareSurveyQuestionsData(survey, category.questions, answers);
  const accountableQuestions = getAccountableQuestions(questions);

  const validRate = getValidRate(accountableQuestions);

  const {
    score,
    minScore: minScoreWithQuestions,
    maxScore,
    filled,
  } = accountableQuestions.reduce(
    (accumulator, { filled, certification }) => ({
      score: accumulator.score + certification.score,
      minScore: accumulator.minScore + certification.minScore,
      maxScore: accumulator.maxScore + certification.maxScore,
      filled: accumulator.filled + (filled ? 1 : 0),
    }),
    { minScore: 0, maxScore: 0, score: 0, filled: 0 },
  );
  const minScore = (category.certification && category.certification.minScore) || minScoreWithQuestions;
  const fillRatio = (filled / accountableQuestions.length) * 100;

  return {
    ...category,
    color: category.color || colors.tertiary,
    questions,
    fillRatio: Math.round(fillRatio * 100) / 100,
    successRatio: validRate || 0,
    certification: {
      minScore,
      maxScore,
      certified: score >= minScore && !accountableQuestions.find(({ certification: { certified } }) => !certified),
      score,
    },

    validRate,
    answered: questions.filter(({ id }) => !!answers[id]).length,
    resultColor: validRate ? getColorFromBounds(validRate / 100) : colors.red,
  };
};

export const prepareSurveyData = (
  survey: EquiReglementation.Database.Survey,
  answers = {},
): EquiReglementation.Survey => {
  const categories = survey.categories.map(category => prepareSurveyCategoryData(survey, category, answers));
  const everyQuestions = categories.reduce<EquiReglementation.Question[]>(
    (accumulator, { questions }) => [...accumulator, ...questions],
    [],
  );

  const accountableQuestions = getAccountableQuestions(everyQuestions);

  const questionsFilled = accountableQuestions.filter(({ filled }) => filled).length;
  const fillRatio = (questionsFilled / accountableQuestions.length) * 100;

  return {
    ...survey,
    fillRatio: Math.round(fillRatio * 100) / 100,
    successRatio: getValidRate(accountableQuestions) || 0,
    certification: {
      certified: !categories.find(category => !category.certification.certified),
      score: categories.reduce((acc, category) => acc + category.certification.score, 0),
    },
    categories,
  };
};
