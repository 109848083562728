import { getRealtimeCollection } from './firebase';

export const REALTIME_DATA = 'realtime/DATA';

export const registerRealtimeHooks = dispatch => {
  const subscriptions = [
    getRealtimeCollection('surveys', { orderBy: [['order', 'asc']] }, data =>
      dispatch({ type: REALTIME_DATA, data, key: 'surveys' }),
    ),
    getRealtimeCollection('usersJobs', { orderBy: [['order', 'asc']] }, data =>
      dispatch({ type: REALTIME_DATA, data, key: 'usersJobs' }),
    ),
    getRealtimeCollection('usersActivityDomains', { orderBy: [['order', 'asc']] }, data =>
      dispatch({ type: REALTIME_DATA, data, key: 'usersActivityDomains' }),
    ),
    getRealtimeCollection('documentsCategories', { orderBy: [['order', 'asc']] }, data =>
      dispatch({ type: REALTIME_DATA, data, key: 'documentsCategories' }),
    ),
    getRealtimeCollection('trainingsCategories', { orderBy: [['order', 'asc']] }, data =>
      dispatch({ type: REALTIME_DATA, data, key: 'trainingsCategories' }),
    ),
  ];

  return () => {
    subscriptions.forEach(unsubscribe => unsubscribe());
  };
};

export const fetchWithCache = async (url, options, { key, ttl = 3600 * 24 }) => {
  try {
    const { expiration, data } = JSON.parse(window.localStorage.getItem(key));

    if (expiration > Date.now() / 1000) {
      return data;
    }

    window.localStorage.removeItem(key);
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  const response = await fetch(url, options);
  const data = await response.json();

  try {
    window.localStorage.setItem(key, JSON.stringify({ data, expiration: Date.now() / 1000 + ttl }));
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return data;
};
