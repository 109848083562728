// @ts-ignore
const sandbox = global.__DEV__ || global.__STAGING__;

export const SHF = {
  ssoEndpoint: (email?: string) => {
    const emailParameter = email ? `&email=${encodeURIComponent(email)}` : '';

    return sandbox
      ? `https://beta-shf.equideclic.com/fr/compte-shf/?site_location=${encodeURIComponent(
          'https://europe-west1-equi-reglementation-staging.cloudfunctions.net/integrators-shf-sso',
        )}${emailParameter}`
      : `https://www.shf.eu/fr/compte-shf/?site_location=${encodeURIComponent(
          'https://europe-west1-outil-auto-diagnostique-cheval.cloudfunctions.net/integrators-shf-sso',
        )}${emailParameter}`;
  },
};
