import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, Button } from '@material-ui/core';

import { firebase } from '../../data/firebase';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2, 2),
  },
  content: {
    padding: theme.spacing(3, 2),
    width: '70%',
    minWidth: '500px',
    margin: 'auto',
  },
  form: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    alignContent: 'center',
    alignItems: 'center',
  },
  action: {
    marginTop: '20px',
  },
}));

export const SignIn = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const onSubmit = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (exception) {
      setError(exception);
    }

    setLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.content}>
        <form className={classes.form}>
          <TextField
            fullWidth
            required
            label="Email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            autoComplete="username"
            disabled={loading}
          />
          <TextField
            fullWidth
            required
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            disabled={loading}
          />
          {error ? (
            <p className={classes.action}>
              Une erreur est survenue. Merci de vérifier vos identifiants et d'essayer à nouveau
            </p>
          ) : null}
          <div className={classes.action}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={loading}>
              Se connecter
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};
