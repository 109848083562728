import { createStore, combineReducers, Store } from 'redux';

import { entity } from './modules/entity/entity.reducer';
import { auth, AuthState } from './modules/auth/auth.reducer';

export type RootState = {
  auth: AuthState;
};

export const store: Store<RootState> = createStore(combineReducers({ entity, auth }));
