import { EquiReglementation } from '@valotvince/equireglementation-utils';

import { AUTH_SIGN_IN, AUTH_SIGN_OUT } from './auth.actions';

export type AuthState = {
  user?: EquiReglementation.User;
};

type Action = {
  [key: string]: any;
  type: string;
};

const initialState = {};

export const auth = (state = initialState, action: Action) => {
  switch (action.type) {
    case AUTH_SIGN_IN:
      return { ...state, user: action.user };
    case AUTH_SIGN_OUT:
      return initialState;
  }

  return state;
};
