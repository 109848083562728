import { REALTIME_DATA } from '../data/realtime';

const initialState = {};

export const entity = (state = initialState, action) => {
  switch (action.type) {
    case REALTIME_DATA:
      return { ...state, [action.key]: action.data };

    default:
      return state;
  }
};
